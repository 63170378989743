import React from 'react';
import { graphql } from 'gatsby';

import { newsletterContent } from './faq.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IFaq, IFaqCategory } from '../models/faq.model';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import SectionNewsletter from '../components/organisms/section-newsletter';
import SectionFaqCategories from '../components/organisms/section-faq-categories';

interface IFaqPageProps {
    readonly data: {
        allFaq: IQueryAllResult<IFaq>;
        allFaqCategory: IQueryAllResult<IFaqCategory>;
    };
}

const FaqPage: React.FC<IFaqPageProps> = ({ data }) => {
    const { t } = useT();
    const { allFaq, allFaqCategory } = data;
    const faqs = getNodes(allFaq);
    const faqCategories = getNodes(allFaqCategory).filter(
        (category) => category.name !== 'alterpress'
    );

    const structuredData = getFaqStructuredData(faqs);

    return (
        <MainLayout showFloatingCircles={true} structuredData={structuredData}>
            <Hero
                titleProps={{ children: t('faq.hero.title'), Tag: 'h1' }}
                color="blue"
                style={{ backgroundColor: 'var(--color-bg-dark)' }}
            />
            <SectionFaqCategories
                theme="light"
                faqs={faqs}
                faqCategories={faqCategories}
                showExpandAllButton={true}
            />
            <SectionNewsletter
                theme="pink"
                inputTheme="semitransparent"
                buttonProps={{ color: 'light' }}
                titleProps={{
                    Tag: 'h2',
                    children: t('faq.newsletter.title'),
                    subtitle: t('faq.newsletter.subtitle'),
                }}
            >
                <h3 className={newsletterContent}>{t('faq.newsletter.content')}</h3>
            </SectionNewsletter>
        </MainLayout>
    );
};

function getFaqStructuredData(faqs: IFaq[]) {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faqs.map((faq) => {
            return {
                '@type': 'Question',
                name: faq.question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: faq.answer,
                },
            };
        }),
    });
}

export const query = graphql`
    query FaqPage($locale: String!) {
        allFaq(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...faqFields
                }
            }
        }
        allFaqCategory(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...faqCategoriesFields
                }
            }
        }
    }
`;

export default FaqPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
